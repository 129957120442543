import request from "@/utils/request";
import cache from "@/utils/cache";

/**
 * 通过卡号登录
 * @param cardNo 卡号
 * @param weizhi 1=余额充值 2=套餐列表 3=套餐订购
 * @returns {*}
 */
export function getInfoByCardNo(cardNo,weizhi) {
    const token = cache.local.get('token');
    return request({
        url: 'iot/getInfoByCardNo',
        method: 'get',
        params: {cardNo,token,weizhi}
    })
}

export function getDongjie(owmNumber) {
    return request({
        url: 'wx/cp/getDongjie',
        method: 'get',
        params: {owmNumber}
    })
}

export function getDalibaoxianshi(cardNo) {
    return request({
        url: 'iot/xinrendalibao',
        method: 'get',
        params: {cardNo}
    })
}

export function getShouyelaoyonghu(cardNo) {
    return request({
        url: 'iot/shouyelaoyonghu',
        method: 'get',
        params: {cardNo}
    })
}

export function goumaixinrendalibao(cardNo,xrlbidx) {
    return request({
        url: 'iot/goumaixinrendalibao',
        method: 'get',
        params: {cardNo,xrlbidx}
    })
}

export function goumaixinrendalibaoBefore(cardNo,xrlbidx) {
    return request({
        url: 'iot/goumaixinrendalibaoBefore',
        method: 'get',
        params: {cardNo,xrlbidx}
    })
}

export function fangqixinrendalibao(cardNo) {
    return request({
        url: 'iot/fangqixinrendalibao',
        method: 'get',
        params: {cardNo}
    })
}


export function getPackageGroup(cardNo) {
    return request({
        url: 'iot/getPackageGroup',
        method: 'get',
        params: {cardNo}
    })
}

/**
 * 赠送永久vip
 * @param kahao 卡号
 * @returns {*}
 */
export function zengsongyongjiuvip(kahao) {
    return request({
        url: '/card/zengsongyongjiuvip',
        method: 'get',
        params: {kahao}
    })
}

/**
 * 取消永久vip
 * @param kahao 卡号
 * @returns {*}
 */
export function quxiaoyongjiuvip(kahao) {
    return request({
        url: '/card/quxiaoyongjiuvip',
        method: 'get',
        params: {kahao}
    })
}

/**
 * 充值页面默认选中
 * @returns {*}
 */
export function czmrxz(kahao) {
    return request({
        url: 'iot/czmrxz',
        method: 'get',
        params: {kahao}
    })
}

/**
 * 赠送活动3
 * @param cardNo
 * @returns {*}
 */
export function zengsonghuodong3(cardNo) {
    return request({
        url: 'iot/zengsonghuodong3',
        method: 'get',
        params: {cardNo}
    })
}

/**
 * 获取微信客服链接
 * @returns {*}
 */
export function getWxkflj() {
    return request({
        url: 'iot/getWxkflj',
        method: 'get'
    })
}

/**
 * 需要跳转的卡号
 * @returns {*}
 */
export function tiaozhuankahao(kahao) {
    return request({
        url: 'iot/tiaozhuankahao',
        method: 'get',
        params: {kahao}
    })
}

/**
 * 取消强制停机
 * @returns {*}
 */
export function quxiaoqiangzhitingji(cardNo1,cardNo2) {
    return request({
        url: 'iot/quxiaoqiangzhitingji',
        method: 'get',
        params: {cardNo1,cardNo2}
    })
}

/**
 * 底部轮播图
 * @returns {*}
 */
export function getBanner() {
    return request({
        url: 'iot/footBanner',
        method: 'get'
    })
}

/**
 * 获取微信jssdk配置
 * @param uri 当前页面链接
 * @returns {*}
 */
export function getWxConfig(uri) {
    return request({
        url: 'iot/wxJsapiConfig',
        method: 'get',
        params: {uri}
    })
}


/**
 * 通过卡号获取姓名、身份证
 * @param cardNo 卡号
 * @returns {*}
 */
export function getIdInfo(cardNo) {
    return request({
        url: 'iot/getIdInfo',
        method: 'get',
        params: {cardNo}
    })
}

/**
 * 记录用户姓名、身份证号
 * @param cardNo 卡号
 * @param idName 姓名
 * @param idNum 身份证号
 * @return 用户是否已实名 isAuthentication 1=已实名 2=未实名
 */
export function recordIdInfo(cardNo,idName,idNum) {
    return request({
        url: 'iot/recordIdInfo',
        method: 'get',
        params: {cardNo,idName,idNum}
    })
}

/**
 * 移动实名结果判断
 * @param cardNo 卡号
 * @param code
 * @param data
 * @param msg
 * @returns {*}
 */
export function yidongRealUrl(cardNo,code,data,msg) {
    return request({
        url: 'iot/yidongRealUrl',
        method: 'get',
        params: {cardNo,code,data,msg}
    })
}


/**
 * 判断是否新手期
 * @param cardNo 卡号
 * @returns {*} xinshouqi 1=是新手期 2=不是新手期
 */
export function xinshouqi(cardNo) {
    return request({
        url: 'iot/xinshouqi',
        method: 'get',
        params: {cardNo}
    })
}

/**
 * 套餐充值页面的自动续费应该怎样显示
 * @param cardNo 卡号
 * @param packageId 购买的套餐id
 * @returns {*} xianshijichubao（显示续订基础包 1显示 2不显示）    xianshijiayoubao（显示续订加油包 1显示 2不显示）
 */
export function zidongxufeixianshi(cardNo,packageId) {
    return request({
        url: 'iot/zidongxufeixianshi',
        method: 'get',
        params: {cardNo,packageId}
    })
}


export function quxiaozidongxufeijichubao(cardNo) {
    return request({
        url: 'iot/quxiaozidongxufeijichubao',
        method: 'get',
        params: {cardNo}
    })
}

export function quxiaozidongxufeijiayoubao(cardNo) {
    return request({
        url: 'iot/quxiaozidongxufeijiayoubao',
        method: 'get',
        params: {cardNo}
    })
}

/**
 * 首页弹窗
 * @param cardNo 卡号
 * @param tcLetter 弹窗顺序
 *                  A 正常顺序弹窗诊断
 *                  B 余额优先弹窗诊断 -- CZ2、CZ1、CZ3，其他正常顺序
 *                  C 套餐优先弹窗诊断 -- 新手期诊断,再进行TC1、TC2、TC3、TC4，其他正常顺序 -- 其中新手期用户点开套餐页面，直接进入到首页
 *                  D 换卡优先弹窗诊断 -- HK1、HK2 ,其他正常顺序
 *                  E 实名优先弹窗诊断 -- SM1 ，其他正常顺序
 * @param shifoushouye 是否首页 1=是 2=否
 * @returns {*} tanchuangleixingList 类型数字列表，根据类型判断界面应该显示什么内容
 */
export function tanchuang(cardNo,tcLetter,shifoushouye) {
    return request({
        url: 'iot/tanchuang',
        method: 'get',
        params: {cardNo,tcLetter,shifoushouye}
    })
}

/**
 * 通过真实卡号获取显示卡号
 * @param cardNo 真实卡号
 * @returns {*}
 */
export function getShowCardNoByRealCardNo(cardNo) {
    return request({
        url: 'iot/getShowCardNo',
        method: 'get',
        params: {cardNo}
    })
}

/**
 * 获取显示卡号列表
 * @returns {*}
 */
export function getShowCardNoList() {
    return request({
        url: 'iot/getShowCardNoList',
        method: 'get'
    })
}

export function getOpenIdPay(openId) {
    return request({
        url: 'iot/getOpenIdPay',
        method: 'get',
        params: {openId}
    })
}



/**
 * 获取页面文字配置
 * @param type 0=常见问题
 * @returns {*}
 */
export function listPageConfig(type) {
    return request({
        url: 'iot/pageConfig',
        method: 'get',
        params: {type}
    })
}


/**
 * 获取用户的卡号列表
 * @param cardNo 卡号
 * @returns {*}
 */
export function getCardNoList(cardNo) {
    return request({
        url: 'iot/getCardNoList',
        method: 'get',
        params: {cardNo}
    })
}

/**
 * 购买余额
 * @param data
 * @returns {*}
 */
export function buyBalance(data) {
    return request({
        url: 'iot/buyBalance',
        method: 'post',
        data
    })
}

/**
 * 解锁套餐
 * @param data
 * @returns {*}
 */
export function jiesuotaocan(data) {
    return request({
        url: 'iot/jiesuotaocan',
        method: 'post',
        data
    })
}

/**
 * 解锁套餐
 * @param data
 * @returns {*}
 */
export function jiesuotaocan2(cardNo,packageId) {
    return request({
        url: 'iot/jiesuotaocan2',
        method: 'get',
        params: {cardNo,packageId}
    })
}

/**
 * 解锁套餐组
 * @param cardNo 卡号
 * @param ztczid 子套餐组id
 * @param jdje 降低金额
 * @returns {*}
 */
export function jiesuotaocanzu(cardNo,ztczid,jdje) {
    return request({
        url: 'iot/jiesuotaocanzu2',
        method: 'get',
        params: {cardNo,ztczid,jdje}
    })
}

export function buchongzhijiesuo(cardNo) {
    return request({
        url: 'iot/buchongzhijiesuo',
        method: 'get',
        params: {cardNo}
    })
}

/**
 * 通过卡号获取临时订单id
 * @param cardNo
 * @returns {*}
 */
export function getTid(cardNo) {
    return request({
        url: 'iot/getTid',
        method: 'get',
        params: {cardNo}
    })
}


/**
 * 换卡
 * @returns {*}
 */
export function huankazhifu(data) {
    return request({
        url: 'iot/huanka',
        method: 'post',
        data
    })
}


/**
 * 解绑
 * @returns {*}
 */
export function jiebang(cardNo) {
    return request({
        url: 'iot/jiebang',
        method: 'get',
        params: {cardNo}
    })
}

/**
 * 获取openId绑定的最后一个卡号
 * @param openId1 铂明科技公众号openId
 * @returns {*}
 */
export function lastCardNoByOpenId(openId1) {
    return request({
        url: 'iot/lastCardNoByOpenId',
        method: 'get',
        params: {openId:openId1}
    })
}


export function goldPlanOrder(outTradeNo) {
    return request({
        url: 'iot/goldPlanOrder',
        method: 'get',
        params: {outTradeNo}
    })
}


/**
 * 获取授权二维码配置
 * @returns {*}
 */
export function getQRCode(id){
    return request({
        url: 'iot/shouquan',
        method: 'get',
        params: {id}
    })
}

/**
 * 获取卡号激活订单
 * @param kahao 卡号
 * @returns {*}
 */
export function getActiveOrder(kahao){
    return request({
        url: 'iot/getActiveOrder',
        method: 'get',
        params: {kahao}
    })
}

/**
 * 申请退款
 * @param data
 * @returns {*}
 */
export function shenqingtuikuan(data){
    return request({
        url: 'iot/sqtkwh',
        method: 'post',
        data
    })
}

/**
 * 获取商品套餐图片
 * @param kahao
 * @returns {*}
 */
export function getTaocantupian(kahao,type){
    return request({
        url: 'iot/getTaocantupian',
        method: 'get',
        params: {kahao,type}
    })
}

export function prePay(zhifubanwei,canshu,type){
    return request({
        url: 'iot/prePay',
        method: 'post',
        data: {zhifubanwei,canshu,type}
    })
}
export function getSbhys(cardNo){
    return request({
        url: 'iot/getSbhys',
        method: 'get',
        params: {cardNo}
    })
}

export function getYuexuanxiang(cardNo){
    return request({
        url: 'iot/getYuexuanxiang',
        method: 'get',
        params: {cardNo}
    })
}

export function getJihuoxuanxiang(cardNo,address,type){
    return request({
        url: 'iot/getJihuoxuanxiang',
        method: 'get',
        params: {cardNo,address,type}
    })
}

export function zfbsb(alipayKey){
    return request({
        url: 'iot/zfbsb',
        method: 'get',
        params: {alipayKey}
    })
}
