import axios from 'axios'
import store from '@/store'
import cache from './cache'
import {Toast} from 'vant';
import {setFirstLog} from "@/utils/index";

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // baseURL: process.env.VUE_APP_BASE_API, // 'http://localhost:10086/'
  baseURL: 'https://yinxing.ihaoma.icu/ldy-sys2/',
  // baseURL: 'http://localhost:10086/',
  // baseURL: 'https://ldysys.ihaoma.icu/ldy-sys/',
  // baseURL: 'https://a.10086-wj.cn/ldy-sys/',
  // baseURL: '/ldy-sys/',
  // 超时
  timeout: 80000
})

// request拦截器
service.interceptors.request.use(config => {
  // 是否需要防止数据重复提交
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
    const requestObj = {
      url: config.url,
      data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
      time: new Date().getTime()
    }
    const sessionObj = cache.session.getJSON('sessionObj')
    if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
      cache.session.setJSON('sessionObj', requestObj)
    } else {
      const s_url = sessionObj.url;                  // 请求地址
      const s_data = sessionObj.data;                // 请求数据
      const s_time = sessionObj.time;                // 请求时间0
      const interval = 1000;                         // 间隔时间(ms)，小于此时间视为重复提交
      if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
        const message = '数据正在处理，请勿重复提交';
        console.warn(`[${s_url}]: ` + message)
        return Promise.reject(new Error(message))
      } else {
        cache.session.setJSON('sessionObj', requestObj)
      }
    }
  }
  return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
      try {
        const responseText = res.request.responseText;
        // if (responseText && res.config.url.includes('/pageConfig')) {
        if (responseText) {
          // yinxing.ihaoma.icu域名证书到期会导致 当前页面所有图片显示不出来，所以这里直接将图片的域名换成a.10086-wj
          const responseTextNew = responseText.replace(/http:\/\/yinxing.ihaoma.icu\/prod-api\/profile/g,'https://a.10086-wj.cn/prod-api/profile')
              .replace(/https:\/\/yinxing.ihaoma.icu\/prod-api\/profile/g,'https://a.10086-wj.cn/prod-api/profile')
          res.data = JSON.parse(responseTextNew);
          // console.log("响应拦截",responseTextNew,res.data)
        }

        if (responseText.includes('没有可用商户')) {
          // 2023年12月1日08:30 yd 技术部-内部 关闭如果加载不出来就跳转first链接功能
          // location.href = 'http://yw.ihaoma.ltd/iot/index.html#/pages/firstRecharge/index';
        }
      } catch (e) {
        console.error("出错了",e)
      }

    // console.log("响应拦截",res);
      if ((res.config && res.config.url || '').includes('getInfoByCardNo')) {
        if (res.data && res.data.info) {
          const owmNumber = res.data.info.owmNumber;
          const showCardNo = res.data.info.showCardNo;
          store.commit('setCardNo',owmNumber)
          store.commit('setShowCardNo',showCardNo)
          // console.log("设置真实卡号/显示卡号",owmNumber,showCardNo)
        }
      }
    return res.data
  },
  error => {
    // const href = location.href;
    // let {baseURL,data,headers,method,url} = error.config
    // let errInfo = JSON.stringify({baseURL,data,headers,method,url,href});
    // console.log('err' + error,errInfo)
    let { message } = error;
    if (message === "Network Error") {
      // 系统挂掉后直接跳转原系统
      setFirstLog("request1 " + JSON.stringify({errorConfig: error.config, message}));
      if (error.config.url === "wx/mp/pageConfig") {
        Toast('刷新一下');
      }else{
        // 2023年12月1日08:30 yd 技术部-内部 关闭如果加载不出来就跳转first链接功能
        // location.href = 'http://yw.ihaoma.ltd/iot/index.html#/pages/firstRecharge/index';
      }
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("status code 502")) {
      // 接口502直接跳转原系统
      setFirstLog("request2 " + JSON.stringify({errorConfig: error.config, message}));
      // 2023年12月1日08:30 yd 技术部-内部 关闭如果加载不出来就跳转first链接功能
      // location.href = 'http://yw.ihaoma.ltd/iot/index.html#/pages/firstRecharge/index';
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    // Toast(message)
    return Promise.reject(error)
  }
)

function tansParams(params) {
  let result = ''
  for (const propName of Object.keys(params)) {
    const value = params[propName];
    var part = encodeURIComponent(propName) + "=";
    if (value !== null && value !== "" && typeof (value) !== "undefined") {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && value[key] !== "" && typeof (value[key]) !== 'undefined') {
            let params = propName + '[' + key + ']';
            var subPart = encodeURIComponent(params) + "=";
            result += subPart + encodeURIComponent(value[key]) + "&";
          }
        }
      } else {
        result += part + encodeURIComponent(value) + "&";
      }
    }
  }
  return result
}

export default service
